//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import LabDetailCard from "../LabDetailCard.vue";
import PaymentDetailCard from "../PaymentDetailCard.vue";
export default {
  name: "PaymentSuccess",
  components: {
    LabDetailCard,
    PaymentDetailCard
  },
  mounted() {
    if (!this.selectedService) {
      this.$router.push({
        name: "customer-payment-history"
      });
    }
  },
  computed: {
    ...mapState({
      selectedService: state => state.testRequest.products,
      detailOrder: state => state.testRequest.order
    })
  }
};