//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PaymentSuccess from "./PaymentSuccess";
export default {
  name: "Success",
  components: {
    PaymentSuccess
  },
  data: () => ({
    stepperItems: [{
      number: 1,
      title: "Select Location and Test Category",
      active: false
    }, {
      number: 2,
      title: "Select Service",
      active: false
    }, {
      number: 3,
      title: "Checkout & Payment",
      active: false
    }, {
      number: 4,
      title: "Summary/Instruction",
      active: true
    }]
  })
};